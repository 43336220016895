var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',_vm._g(_vm._b({ref:"form",on:{"input":_vm.onInputLevelValidChanged,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();$event.stopPropagation();}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.inputLevelValid),callback:function ($$v) {_vm.inputLevelValid=$$v},expression:"inputLevelValid"}},'v-form',_vm.options.attrs,false),_vm.options.on),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_vm._t("top",null,{"form":_vm.self}),(_vm.validating.value)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.options.attrs.title)?_c('v-card-title',{staticClass:"pa-0 mb-3",class:_vm.options.attrs.titleAttrs.class,style:({
      'text-transform': _vm.options.attrs.titleAttrs.uppercase ? 'uppercase' : '',
      'font-size': _vm.options.attrs.titleAttrs['font-size'],
      color: _vm.options.attrs.titleAttrs.color,
    })},[_vm._v(_vm._s(_vm.options.attrs.title)+" ")]):_vm._e(),(_vm.error.value)?_c('v-alert',{attrs:{"type":"error"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error.value)}})]):_vm._e(),(_vm.success.value)?_c('v-alert',{attrs:{"type":"success"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.success.value)}})]):_vm._e(),_vm._l((_vm.options.content.sections),function(section,sectionName){return [(!section.ext || (!section.ext.hidden && (!section.ext.condition || section.ext.condition(_vm.self))))?_c('div',{key:sectionName},[(section.header)?[_c('v-toolbar',{staticClass:"my-8",attrs:{"dark":"","dense":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(section.header)+" ")])],1)]:_vm._e(),_c('v-row',[_vm._l((section.fields),function(field,fieldName){return [(!field.ext || (!field.ext.hidden && (!field.ext.condition || field.ext.condition(_vm.self))))?_c('v-col',_vm._b({key:fieldName + '-' + _vm.version,attrs:{"cols":"12","sm":(field.ext && field.ext.colLength) || _vm.colLength}},'v-col',_vm.options.attrs.inputContainerAttrs,false),[(!field.ext || !field.ext.blank)?_c('div',[_c('x-input',{ref:fieldName,refInFor:true,attrs:{"x-options":field,"x-model":_vm.inputs[fieldName],"x-context":_vm.self,"x-disabled":_vm.disabled,"x-container":_vm.inputContainers[fieldName]},on:{"input":function($event){_vm.onInputChanged(fieldName, $event);
                  field.ext && field.ext.onInput && field.ext.onInput($event, _vm.self);},"change":function($event){_vm.onInputChanged(fieldName, $event);
                  field.ext && field.ext.onInput && field.ext.onInput($event, _vm.self);},"validated":function($event){_vm.inputValids[fieldName] = $event;
                  _vm.onInputLevelValidChanged();}}})],1):_vm._e()]):_vm._e()]})],2)],2):_vm._e()]}),(_vm.loading.value)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.options.content.actionButtons)?_c('div',{staticClass:"form-action mt-4 text-right"},[_vm._l((_vm.options.content.actionButtons),function(btn,key){return [(!btn.ext || (!btn.ext.hidden && (!btn.ext.condition || btn.ext.condition(_vm.self))))?_c('x-btn',_vm._b({key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-default-class":"dataTable.content.template.topActionButton"}},'x-btn',btn.ext && btn.ext.makeAttrs && btn.ext.makeAttrs(_vm.self),false)):_vm._e()]})],2):_vm._e(),_vm._t("bottom",null,{"form":_vm.self})],2)}
var staticRenderFns = []

export { render, staticRenderFns }